import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';

const Hero = () => {
  const handleScrollToAbout = () => {
    const aboutSection = document.getElementById('about');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('The About section with id="about" was not found in the DOM.');
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        background: 'url(/back3.jpeg) center/cover no-repeat',
        color: '#FFF',
        textAlign: 'center',
      }}
    >
      {/* Overlay for readability */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.4)', // Semi-transparent black overlay
          zIndex: 1,
        }}
      ></Box>

      {/* Content */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          padding: '0 20px',
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }} textAlign="left">
          Scale Your Business
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }} textAlign="left">
          Navigate Global Markets Through Strategic Innovation
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={handleScrollToAbout}
            sx={{
              backgroundColor: '#6FAE75',
              padding: '10px 20px',
              '&:hover': { backgroundColor: '#558D61' },
            }}
          >
            Learn More
          </Button>
          <Button
            variant="outlined"
            href="https://www.youtube.com/@rita-biz"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: '#FFF',
              borderColor: '#6FAE75',
              padding: '10px 20px',
              '&:hover': {
                borderColor: '#558D61',
                color: '#6FAE75',
              },
            }}
          >
            Watch YouTube
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default Hero;
