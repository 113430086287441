//
//import React from 'react';
//import { Box, Typography, Button } from '@mui/material';
//
//const Contact = () => (
//  <Box
//    sx={{
//      py: 10,
//      textAlign: 'center',
//      backgroundColor: '#F7F9FB',
//      color: '#212A31',
//    }}
//  >
//    <Typography variant="h4" sx={{ mb: 3 }}>
//      Let’s Work Together
//    </Typography>
//    <Typography variant="body1" sx={{ mb: 4 }}>
//      Ready to take the next step in leadership and innovation? Contact me today.
//    </Typography>
//    <Button
//      variant="contained"
//      sx={{
//        backgroundColor: '#3D52A0',
//        padding: '10px 20px',
//        '&:hover': { backgroundColor: '#2E3944' },
//      }}
//    >
//      Get in Touch
//    </Button>
//    <Box sx={{ mt: 4 }}>
//            <Typography variant="h6" gutterBottom>Email</Typography>
//            <Typography variant="body1">
//              <a href="mailto:m.stanislavava@gmail.com" style={{ textDecoration: 'none', color: '#3a5da8' }}>
//                m.stanislavava@gmail.com
//              </a>
//            </Typography>
//            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
//              LinkedIn
//            </Typography>
//            <Typography variant="body1">
//              <a
//                href="https://www.linkedin.com/in/marharyta-stanislavava/"
//                target="_blank"
//                rel="noopener noreferrer"
//                style={{ textDecoration: 'none', color: '#3a5da8' }}
//              >
//                linkedin.com/in/marharyta-stanislavava
//              </a>
//            </Typography>
//          </Box>
//  </Box>
//);
//
//export default Contact;

import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';

const Footer = () => (
  <Box
    sx={{
      backgroundColor: '#1f4222',
      color: '#FFF',
      textAlign: 'center',
      padding: '20px',
    }}
  >
    <Typography variant="h6">Get in Touch</Typography>
    <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 2 }}>
      <Button
        variant="contained"
                href="mailto:m.stanislavava@gmail.com"
        sx={{
          backgroundColor: '#FFF',
          color: '#6FAE75',
          '&:hover': { backgroundColor: '#E5E5E5' },
        }}
      >
        Contact Me
      </Button>
      <Button
        variant="outlined"
        href="https://www.linkedin.com/in/marharyta-stanislavava/"
                target="_blank"
                rel="noopener noreferrer"
        sx={{
          color: '#FFF',
          borderColor: '#FFF',
          '&:hover': { borderColor: '#E5E5E5' },
        }}
      >
        LinkedIn
      </Button>
      <Button
        variant="outlined"
                href="https://www.youtube.com/@rita-biz"
                target="_blank"
                rel="noopener noreferrer"
        sx={{
          color: '#FFF',
          borderColor: '#FFF',
          '&:hover': { borderColor: '#E5E5E5' },
        }}
      >
        YouTube
      </Button>
    </Stack>
  </Box>
);

export default Footer;
