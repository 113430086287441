import React from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent, Button } from '@mui/material';

const highlights = [
  {
    title: 'Case Study: International Market Entry',
    description: 'Explore how I helped a mid-sized company expand into emerging markets successfully.',
    image: '/market.jpeg',
    //link: '/case-study',
  },
  {
    title: 'Latest Blog: Digital Trends in 2024',
    description: 'Discover the key digital trends shaping the future of business and technology.',
    image: '/digital.jpeg',
    //link: '/blog',
  },
  {
    title: 'YouTube: Leadership in a Global Economy',
    description: 'Watch my latest video discussing leadership challenges in international management.',
    image: '/Leadership in a Global Economy.jpeg',
    link: 'https://www.youtube.com/@rita-biz',
  },
];

const Highlight = () => (
  <Box
    sx={{
      backgroundColor: '#E5E5E5',
      padding: '40px 20px',
      textAlign: 'center',
    }}
  >
    <Typography variant="h4" sx={{ color: '#1f4222', mb: 4 }}>
      Highlights
    </Typography>
    <Grid container spacing={4} justifyContent="center">
      {highlights.map((highlight, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card sx={{ boxShadow: 2, backgroundColor: '#FFF' }}>
            <CardMedia
              component="img"
              height="180"
              image={highlight.image}
              alt={highlight.title}
              sx={{ borderRadius: '6px 6px 0 0' }}
            />
            <CardContent>
              <Typography variant="h6" sx={{ color: '#1f5f25', mb: 1 }}>
                {highlight.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#333', mb: 2 }}>
                {highlight.description}
              </Typography>
              <Button
                variant="contained"
                href={highlight.link}
                target="_blank"
                sx={{
                  backgroundColor: '#1f5f25',
                  color: '#FFF',
                  '&:hover': { backgroundColor: '#558D61' },
                }}
              >
                Learn More
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default Highlight;
